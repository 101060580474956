import * as React from "react";
import { Link } from "gatsby";
import "./styles.scss";

const StyledLink = ({ children, to, asButton, rewardsButton }) => {
    return (
        <>
        {asButton ? (
            <Link className="link-component btn btn-primary btn-block" to={to}>
                {children}
            </Link>
        ) : rewardsButton ? (
            <div className="button-container">
                <Link className="btn btn-primary btn-block btn-rewards" to={to}>
                    {children}
                </Link>
            </div>
        ) : (
            <Link className="link-component" to={to}>
                {children}
            </Link>
        )}
        </>
    );
};
export default StyledLink;
