import * as React from "react";
import Layout from "../_components/Layout/Layout";
import LoginForm from "../_components/LoginForm/LoginForm";
import MainTitle from "../_components/MainTitle/MainTitle";
import StyledLink from "../_components/StyledLink/StyledLink";
import PageSummary from "../_components/PageSummary/PageSummary";

const Login = () => {
    return (
        <Layout title="Login">
            <MainTitle>WELCOME BACK</MainTitle>

            <PageSummary>
                Enter your email to claim your treat
            </PageSummary>

            <LoginForm />

            <hr />

            <StyledLink to="/registration">
                Go back to registration
            </StyledLink>
        </Layout>
    );
};

export default Login;
