import * as React from "react";
import { useEffect, useState } from "react";
import "./styles.scss";
import { goto } from "../../_utils/Misc";
import Qr from "../../_utils/Qr";
import MainTitle from "../MainTitle/MainTitle";

export default function LoginForm() {
    const [bodyMessage, setBodyMessage] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const [email, setEmail] = useState("");

    // Check query params data and is something is not OK go to the home
    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            goto("/");
        }
    });

    // Send login request
    let handleSubmit = (event) => {
        event.preventDefault();

        // Get qr parameters
        let qrParams = Qr.getStoredParams();

        const requestData = {
            "click_id": qrParams.clickId,
            "campaign_id": qrParams.campaignId,
            "code": qrParams.code,
            "variant": qrParams.variant,
            "consumer_email": email,
        };

        setFormDisabled(true);

        fetch(`${process.env.GATSBY_FABACUS_API_URL}v1/login`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
            .then(async(response) => {
                setFormDisabled(false);

                const responseObject = await response.json();
                const status = response.status;

                if (!responseObject || !responseObject.message_title || !responseObject.message_message) {
                    throw new Error("Bad response!");
                }

                if (status === 200) {
                    setEmail("");
                }

                setBodyMessage(`${responseObject.message_title} - ${responseObject.message_message}`);
            })
            .catch(e => {
                setFormDisabled(false);
                console.error("Login Error", e);
                setBodyMessage("There was a problem with your request. Please try again later!");
            });
    };

    return bodyMessage ? (<MainTitle>{bodyMessage}</MainTitle>) : (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label
                    htmlFor="f-email"
                    className="form-label">
                    Email
                </label>

                <input
                    type="email"
                    id="f-email"
                    className="form-control"
                    required="required"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className="d-grid gap-2">
                <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={formDisabled}>
                    CLAIM MY TREATS
                </button>
            </div>
        </form>
    );
}
